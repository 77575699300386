import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";

const Nav = ({ setLibraryStatus, libraryStatus }) => {
  const openLibraryHandler = () => {
    setLibraryStatus(!libraryStatus);
  };

  async function getter(){
    
    return await axios({
    method: "get",
    // headers: {
    //   'Cross-Origin-Embedder-Policy': 'require-corp',
    //   'Cross-Origin-Opener-Policy': 'same-origin'
    // },
    url: "./pages/40doa.pdf",
    responseType: "arraybuffer"
  })
    .then((response) => {
      var link = document.createElement("a");
      link.href = window.URL.createObjectURL(
        new Blob([response.data], { type: "application/octet-stream" })
      );
      // link.download = "ebook.pdf";
        link.setAttribute("download","ebook.pdf");
        // link.setAttribute("href","ebook.pdf");

      document.body.appendChild(link);
  
      link.click();
      setTimeout(function () {
        window.URL.revokeObjectURL(link);
      }, 200);
    })
    .catch((error) => {});
  }




  return (
    <nav>
      
      <button
      onClick={getter}
      > تنزيل الكتاب  </button>

      <button
        className={libraryStatus ? "library-active" : ""}
        onClick={openLibraryHandler}
      >
        
        <FontAwesomeIcon icon={faBars} size="2x"></FontAwesomeIcon>
      </button>
    </nav>
  );
};

export default Nav;
