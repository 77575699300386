function chillHop() {
  return [
    {
      name:"الدعاء الأول",
      artist:"أحمد عبيد الغفلي",
      cover:"./pages/01.jpg",
      audio:"./audio/01.mp3",
      id:"01",
      active: true},
      {
      name:"الدعاء الثاني",
      artist:"أحمد عبيد الغفلي",
      cover:"./pages/02.jpg",
      audio:"./audio/02.mp3",
      id:"02",
      active: true},
      {
      name:"الدعاء الثالث",
      artist:"أحمد عبيد الغفلي",
      cover:"./pages/03.jpg",
      audio:"./audio/03.mp3",
      id:"03",
      active: true},
      {
      name:"الدعاء الرابع",
      artist:"أحمد عبيد الغفلي",
      cover:"./pages/04.jpg",
      audio:"./audio/04.mp3",
      id:"04",
      active: true},
      {
      name:"الدعاء الخامس",
      artist:"أحمد عبيد الغفلي",
      cover:"./pages/05.jpg",
      audio:"./audio/05.mp3",
      id:"05",
      active: true},
      {
      name:"الدعاء السادس",
      artist:"أحمد عبيد الغفلي",
      cover:"./pages/06.jpg",
      audio:"./audio/06.mp3",
      id:"06",
      active: true},
      {
      name:"الدعاء السابع",
      artist:"سيد عبدالله",
      cover:"./pages/07.jpg",
      audio:"./audio/07.mp3",
      id:"07",
      active: true},
      {
      name:"الدعاء الثامن",
      artist:"سيد عبدالله",
      cover:"./pages/08.jpg",
      audio:"./audio/08.mp3",
      id:"08",
      active: true},
      {
      name:"الدعاء التاسع",
      artist:"سيد عبدالله",
      cover:"./pages/09.jpg",
      audio:"./audio/09.mp3",
      id:"09",
      active: true},
      {
      name:"الدعاء العاشر",
      artist:"سيد عبدالله",
      cover:"./pages/10.jpg",
      audio:"./audio/10.mp3",
      id:"10",
      active: true},
      {
      name:"الدعاء الحادي عشر",
      artist:"عبدالرحمن بن ديريه",
      cover:"./pages/11.jpg",
      audio:"./audio/11.mp3",
      id:"11",
      active: true},
      {
      name:"الدعاء الثاني عشر",
      artist:"عبدالرحمن بن ديريه",
      cover:"./pages/12.jpg",
      audio:"./audio/12.mp3",
      id:"12",
      active: true},
      {
      name:"الدعاء الثالث عشر",
      artist:"عبدالرحمن بن ديريه",
      cover:"./pages/13.jpg",
      audio:"./audio/13.mp3",
      id:"13",
      active: true},
      {
      name:"الدعاء الرابع عشر",
      artist:"عبدالرحمن بن ديريه",
      cover:"./pages/14.jpg",
      audio:"./audio/14.mp3",
      id:"14",
      active: true},
      {
      name:"الدعاء الخامس عشر",
      artist:"عبدالرشيد جامع",
      cover:"./pages/15.jpg",
      audio:"./audio/15.mp3",
      id:"15",
      active: true},
      {
      name:"الدعاء السادس عشر",
      artist:"عبدالرشيد جامع",
      cover:"./pages/16.jpg",
      audio:"./audio/16.mp3",
      id:"16",
      active: true},
      {
      name:"الدعاء السابع عشر",
      artist:"عبدالرشيد جامع",
      cover:"./pages/17.jpg",
      audio:"./audio/17.mp3",
      id:"17",
      active: true},
      {
      name:"الدعاء الثامن عشر",
      artist:"عبدالرشيد جامع",
      cover:"./pages/18.jpg",
      audio:"./audio/18.mp3",
      id:"18",
      active: true},
      {
      name:"الدعاء التاسع عشر",
      artist:"عبدالله المشجري",
      cover:"./pages/19.jpg",
      audio:"./audio/19.mp3",
      id:"19",
      active: true},
      {
      name:"الدعاء العشرون",
      artist:"عبدالله المشجري",
      cover:"./pages/20.jpg",
      audio:"./audio/20.mp3",
      id:"20",
      active: true},
      {
      name:"الدعاء الحادي والعشرون",
      artist:"عبدالله المشجري",
      cover:"./pages/21.jpg",
      audio:"./audio/21.mp3",
      id:"21",
      active: true},
      {
      name:"الدعاء الثاني والعشرون",
      artist:"عبدالله المشجري",
      cover:"./pages/22.jpg",
      audio:"./audio/22.mp3",
      id:"22",
      active: true},
      {
      name:"الدعاء الثالث والعشرون",
      artist:"عدنان البلوشي",
      cover:"./pages/23.jpg",
      audio:"./audio/23.mp3",
      id:"23",
      active: true},
      {
      name:"الدعاء والرابع والعشرون",
      artist:"عدنان البلوشي",
      cover:"./pages/24.jpg",
      audio:"./audio/24.mp3",
      id:"24",
      active: true},
      {
      name:"الدعاء الخامس والعشرون",
      artist:"عدنان البلوشي",
      cover:"./pages/25.jpg",
      audio:"./audio/25.mp3",
      id:"25",
      active: true},
      {
      name:"الدعاء السادس والعشرون",
      artist:"عدنان البلوشي",
      cover:"./pages/26.jpg",
      audio:"./audio/26.mp3",
      id:"26",
      active: true},
      {
      name:"الدعاء السابع والعشرون",
      artist:"محمد بن ديريه",
      cover:"./pages/27.jpg",
      audio:"./audio/27.mp3",
      id:"27",
      active: true},
      {
      name:"الدعاء الثامن والعشرون",
      artist:"محمد بن ديريه",
      cover:"./pages/28.jpg",
      audio:"./audio/28.mp3",
      id:"28",
      active: true},
      {
      name:"الدعاء التاسع والعشرون",
      artist:"محمد بن ديريه",
      cover:"./pages/29.jpg",
      audio:"./audio/29.mp3",
      id:"29",
      active: true},
      {
      name:"الدعاء الثلاثون",
      artist:"محمد بن ديريه",
      cover:"./pages/30.jpg",
      audio:"./audio/30.mp3",
      id:"30",
      active: true},
      {
      name:"الدعاء الحادي والثلاثون",
      artist:"محمد يماني",
      cover:"./pages/31.jpg",
      audio:"./audio/31.mp3",
      id:"31",
      active: true},
      {
      name:"الدعاء الثاني والثلاثون",
      artist:"محمد يماني",
      cover:"./pages/32.jpg",
      audio:"./audio/32.mp3",
      id:"32",
      active: true},
      {
      name:"الدعاء الثالث والثلاثون",
      artist:"محمد يماني",
      cover:"./pages/33.jpg",
      audio:"./audio/33.mp3",
      id:"33",
      active: true},
      {
      name:"الدعاء الرابع والثلاثون",
      artist:"محمد يماني",
      cover:"./pages/34.jpg",
      audio:"./audio/34.mp3",
      id:"34",
      active: true},
      {
      name:"الدعاء الخامس والثلاثون",
      artist:"محمد الفاتح",
      cover:"./pages/35.jpg",
      audio:"./audio/35.mp3",
      id:"35",
      active: true},
      {
      name:"الدعاء السادس والثلاثون",
      artist:"محمد الفاتح",
      cover:"./pages/36.jpg",
      audio:"./audio/36.mp3",
      id:"36",
      active: true},
      {
      name:"الدعاء السابع والثلاثون",
      artist:"محمد الفاتح",
      cover:"./pages/37.jpg",
      audio:"./audio/37.mp3",
      id:"37",
      active: true},
      {
      name:"الدعاء الثامن والثلاثون",
      artist:"محمد الفاتح",
      cover:"./pages/38.jpg",
      audio:"./audio/38.mp3",
      id:"38",
      active: true},
      {
      name:"الدعاء التاسع والثلاثون",
      artist:"نوح عبدالرحمن شيخ",
      cover:"./pages/39.jpg",
      audio:"./audio/39.mp3",
      id:"39",
      active: true},
      {
      name:"الدعاء الأربعون",
      artist:"نوح عبدالرحمن شيخ",
      cover:"./pages/40.jpg",
      audio:"./audio/40.mp3",
      id:"40",
      active: true},
      {
      name:"الدعاء الحادي والأربعون",
      artist:"نوح عبدالرحمن شيخ",
      cover:"./pages/41.jpg",
      audio:"./audio/41.mp3",
      id:"41",
      active: true},
      {
      name:"الدعاء الثاني والأربعون",
      artist:"نوح عبدالرحمن شيخ",
      cover:"./pages/42.jpg",
      audio:"./audio/42.mp3",
      id:"42",
      active: true},
    //ADD MORE HERE
  ];
}

export default chillHop;
